body {
  margin: 0;
  overflow-x: hidden;
}
main {
  background: linear-gradient(180deg, #391b6b 4.03%, #0a1749 120%);
}
::-webkit-scrollbar {
  width: 5px;
  opacity: 0.5;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  opacity: 0.5;
}

::-webkit-scrollbar-thumb {
  background: #888;
  opacity: 0.5;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  opacity: 0.5;
}
