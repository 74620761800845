footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 132px;
  background: linear-gradient(180deg, #171952 0%, #3a1c6c 100%);
  h2 {
    font-family: 'Aquire';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 35px;

    color: #ffffff;
  }
  .footer-links-wrapper {
    margin-top: 57px;
    display: flex;
    gap: 65px;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer-links-container {
    display: flex;
    gap: 20px;
    margin-top: 24px;
    align-items: center;
    a {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      @media screen and (max-width: 900px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin-top: 94px;
    margin-bottom: 35px;
  }
  span {
    color: white;
  }
}
